import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const CloudManagementProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Enhance cloud operational efficiency, automation, and visibility with{' '}
        <span className="heading-red">
          Simform's Cloud Management Solutions
        </span>
      </>
    ),
    bannerContent:
      'Our experts continuously monitor your cloud environment, manage resources efficiently, and ensure smooth operations so you can deliver excellent customer experiences.',
    BannerAlt: `Simform's Cloud Management Solutions`,
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Simplify AWS management and optimize with{' '}
        <span className="highlight-head">Simform</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            Managing an AWS environment is complex, with the risks of security
            breaches, higher costs, and operational inefficiencies if not done
            properly. The dynamic nature of the cloud strains IT teams trying to
            maintain control and optimize performance.
            <br /> <br />
            As an AWS Premier Partner, Simform conducts detailed assessments,
            implements automated deployment strategies, and configures AWS
            resources based on your business requirements. With our expertise
            and strategic execution, you can optimize your cloud environment
            end-to-end, reduce risks, and focus your resources on core
            innovation.
          </>
        ),
      },
      {
        detailsList: [
          'Comprehensive cloud infrastructure governance',
          'Efficient resource provisioning and optimization',
          'Streamlined monitoring and compliance',
          'Expertise in AWS security practices',
          'Cloud cost management strategies',
          'Scalable cloud deployments for growth',
          'Integration of AWS-native services for efficiency',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  serviceScrollSecProps: {
    servicesHeading: (
      <>
        Our AWS Cloud management{' '}
        <span className="highlight-head">services</span>
      </>
    ),
    servicesPara:
      'Our AWS-certified architects go beyond surface-level monitoring to deeply understand your environment, challenges, and goals. With this insight, we design roadmaps and configurations to enhance performance, reduce costs, and strengthen security across your cloud ecosystem.',
    sidebarHeading: 'Services',
    serviceListProps: [
      {
        heading: 'Amazon Config',
        className: 'custom-software',
        mainPara: (
          <>
            Our Amazon Config experts assess, monitor, and evaluate your
            resource configurations to provide a comprehensive view of your AWS
            ecosystem. We simplify change management workflows to enhance
            compliance and ensure the security of your AWS resources.
          </>
        ),
        listItem: [
          {
            title: 'Efficient data aggregation',
            para:
              'We set up systems for reliability and compliance to ensure smooth multi-resource data alignment with your policies.',
          },
          {
            title: 'Enhanced configuration checks',
            para:
              'Our experts conduct pre and post-deployment evaluations to identify resources needing remediation and assess their impact on system performance.',
          },
          {
            title: 'AWS config migration',
            para:
              'Smooth transition to AWS Config with managed auditing, recording, and monitoring of configuration changes which free you for core business activities.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-config/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'AWS System Manager',
        className: 'software-testing',
        mainPara: `Simform helps you automate operational tasks, simplifies resource management, and ensures seamless system maintenance. Leveraging Systems Manager's capabilities, our AWS experts streamline patching processes for enhanced security and optimize system performance, ensuring your infrastructure operates efficiently.`,
        listItem: [
          {
            title: 'Efficient Operation management',
            para:
              'We centralize your AWS resources, track assets, and optimize costs with our AWS Systems Manager expertise to enhance efficiency and resource optimization.',
          },
          {
            title: 'Quick Incident Handling',
            para:
              'Efficiently handle AWS incidents using OpsItems for tracking and automated remediation to minimize disruptions through resource event timeline analysis.',
          },
          {
            title: 'Node Management Expertise',
            para:
              'We manage hybrid-enabled nodes, support instant changes, deploy packages, and skillfully oversee resources for seamless system operations.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-system-manager/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'AWS CloudFormation',
        className: 'enterprise-mobile',
        mainPara:
          'Our AWS experts leverage CloudFormation to simplify infrastructure management through code-based provisioning and configuration. We create templates to standardize deployments and set up predictable environments to enable rapid scaling while maintaining security and governance.',
        listItem: [
          {
            title: 'Tailored AWS deployments',
            para:
              'Our experts customize AWS deployments, integrate third-party solutions for enhanced functionality, and boost system efficiency to meet your business needs.',
          },
          {
            title: 'Automated infrastructure',
            para:
              'We streamline performance, eliminate manual management, and enable flexible auto-scaling through automation infrastructure provisioning.',
          },
          {
            title: 'Streamlined configuration management',
            para:
              'Our experts use diverse models for node configuration through custom stacks to allow infrastructure customization across environments.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/aws-cloudformation/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Amazon Connect',
        className: 'web-application',
        mainPara:
          'We assist you in designing and deploying omnichannel contact center solutions on AWS, meticulously engineered for cost-efficiency and agent productivity. This facilitates seamless customer interactions across channels through contextual engagement and rapid issue resolution.',
        listItem: [
          {
            title: 'Tailored development solutions',
            para:
              'We create custom applications and features within Amazon Connect to meet specific business needs, including chatbots and virtual assistants.',
          },
          {
            title: 'Efficient integration',
            para:
              'Our well-designed workflows seamlessly integrate Amazon Connect with your existing systems and applications, including CRM platforms, and helpdesk software.',
          },
          {
            title: 'Performance optimization',
            para:
              'We use quality assurance to monitor and improve customer interactions to optimize call flows and routing for enhanced performance.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/amazon-connect/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Amazon CloudFront',
        className: 'dedicated-software',
        mainPara:
          'Simform strategically positions edge locations worldwide and optimizes caching to create a high-performing CDN that delivers content at blazing speed. Our experts assess your current content delivery network. We then fine-tune CloudFront configurations based on traffic patterns and content types to ensure swift delivery globally.',
        listItem: [
          {
            title: 'Global edge selection',
            para:
              'Our experts analyze user base and traffic to strategically select edge locations to minimize latency and seamlessly deliver global content.',
          },
          {
            title: 'Mobile & media optimization',
            para: `We use CloudFront's device detection to optimize content for mobile devices and configure adaptive bitrate delivery for seamless media streaming.`,
          },
          {
            title: 'Secure online assets',
            para:
              'With AWS WAF, HTTPS support, and DDoS protection, we fortify your online assets against attacks and instill trust by safeguarding content and data.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/amazon-cloudfront/  ',
        type: EXTERNAL_LINK,
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Why choose <span className="highlight-head"> Simform?</span>
      </>
    ),
    processPara:
      'With a team of 200+ AWS-certified experts, Simform delivers proven solutions informed by years of hands-on cloud management experience. With multiple AWS Competencies and Service Delivery Partnerships, Simform has maintained its status as one of the most qualified cloud engineering partners for companies across industries. Our commitment is to ensure everything runs smoothly, your cloud costs are optimized, and your customers are happy with the user experience.',
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Intrinsically agile',
        workingBenefitsContent: (
          <>
            Simform adapts to your dynamic business needs and ensures swift
            responses to changes and challenges within the AWS ecosystem. <br />
            <br />
            Our agile approach fosters continuous innovation and seamless
            integration, allowing for rapid adjustments and optimizations to
            match your evolving requirements.
          </>
        ),
        benefitImgAlt: 'Intrinsically agile',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Delivery mindset',
        workingBenefitsContent: (
          <>
            We prioritize timely and efficient service provisioning, emphasizing
            prompt and reliable execution of cloud management solutions.
            <br />
            <br />
            Our focus remains on consistent delivery, meeting project
            milestones, and exceeding your expectations.
          </>
        ),
        benefitImgAlt: 'Delivery mindset',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Eminent expertise',
        workingBenefitsContent: (
          <>
            Simform's seasoned team of 200+ AWS professionals possesses
            comprehensive knowledge and experience in cloud management.
            <br />
            <br />
            With a deep understanding of AWS intricacies, our experts offer
            top-tier guidance and support to navigate complex cloud landscapes
            with confidence.
          </>
        ),
        benefitImgAlt: 'Eminent expertise',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Clear communication',
        workingBenefitsContent: (
          <>
            Our experts ensure transparent and effective collaboration with
            clients, fostering a deep understanding of their requirements and
            objectives.
            <br />
            <br />
            We prioritize open and honest dialogue, providing regular updates
            and insights into project progress and performance.
          </>
        ),
        benefitImgAlt: 'Clear communication',
      },
    ],
  },
  gauranteeSecProps: {
    heading: (
      <>
        Work with an official{' '}
        <span className="highlight-head">AWS Premier Consulting Partner</span>
      </>
    ),
    para:
      'Simform, with its cloud partners, brings you the superpower of cloud-native applications. Our strategic alliances empower organizations like yours to thrive in the cloud.',
    img: images.gauranteeTeam,
    img2x: images.gauranteeTeam2x,
    gauranteeAlt: 'Extended tech team',
  },
  awardsRecognitionProps: {
    heading: 'Learn more about our AWS partner programs',
    AwsCardData: [
      {
        cardLink: '/aws-well-architected-review/',
      },
      {
        cardLink: '/aws-immersion-days/',
      },
    ],
  },
  relatedcaselistProps: {
    heading: (
      <>
        <span className="highlight-head">Case Studies</span>
      </>
    ),
    caselistProps: [
      {
        title: 'Newton',
        para: 'An ultimate all-inclusive email management solution.',
        className: 'pale-blue',
        link: '/newton-connect/ ',
      },
      {
        title: 'Sweet Analytics',
        para: '360 marketing analytics platform for ecommerce leaders',
        className: 'light-peach',
        link: '/sweet-analytics/',
      },
    ],
  },
  rangeServiceListDataProps: {
    RangeServiceListHeading: 'About Simform’s AWS Practices',
    detailsList: [
      <>
        <strong>
          Simform is an official premier consulting partner of AWS
        </strong>{' '}
        and hence, follows an approved set of practices that define the best
        work in configuring AWS services.
      </>,
      <>
        <strong>Devoted subject matter expert</strong> to walk you through your
        project and assist teams during the problems.
      </>,
      <>
        <strong>A well-structured team</strong> of technical officers whose
        significant tenure belongs to cloud technologies and AWS services.
      </>,
      <>
        <strong>A 24/7 constant communication</strong> to help you get through
        the AWS assistance, zero instances of delayed service.
      </>,
      <>
        <strong>Simform’s standard auditing and quality assurance</strong> over
        every module of code we deliver through our consulting services.
      </>,
    ],
  },
  featureResourceProps: {
    heading: 'AWS insights from our experts',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/blog/lift-and-shift/',
        featurecardTitle:
          'Lift and Shift: A Holistic Approach to Application Modernization',
      },
      {
        blogLink: 'https://www.simform.com/blog/cloud-migration-challenges/',
        featurecardTitle:
          '13 Cloud Migration Challenges and Solutions For CTOs in 2023',
      },
      {
        blogLink: 'https://www.simform.com/blog/cloud-migration-strategy/',
        featurecardTitle:
          'Cloud Migration Strategy – The Ultimate Guide to the 6 R’s',
      },
    ],
  },
}
